import React from "react";
import faceid from "./faceid.gif";

const loginStyles = {
	root: {
		display: "flex",
		flexDirection: "column" as any,
		justifyContent: "start",
		alignItems: "center",
		height: "100vh",
		width: "100vw",
		backgroundColor: "#FFFFFF",
		transition: "all 0.25s",
		animation: "fade-in 0.25s",
		gap: "10px",
	},
	header: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100px",
		width: "100vw",
		fontFamily: '"Montserrat", sans-serif',
	},
	icon: {
		height: "80px",
	},
	quickGlance: {
		width: "100vw",
		height: "fit-content",
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		flexDirection: "column" as any,
		marginTop: "2vh",
		position: "relative" as any,
	},
	status: {
		border: "10px solid #B4E279",
		borderRadius: "999em",
		width: "60vw",
		aspectRatio: "1/1",
	},
	statusCutter: {
		width: "70vw",
		backgroundColor: "#FFFFFF",
		height: "29vw",
		position: "absolute" as any,
		bottom: "0",
	},
	radioButtonFull: {
		width: "15px",
		height: "15px",
		borderRadius: "999em",
		backgroundColor: "#E7022A",
		position: "absolute" as any,
		top: "50%",
		left: "50%",
		translate: "-150% -50%",
	},
	radioButtonEmpty: {
		width: "15px",
		height: "15px",
		borderRadius: "999em",
		backgroundColor: "#888888",
		position: "absolute" as any,
		top: "50%",
		left: "50%",
		translate: "50% -50%",
	},
	quickActions: {
		width: "100vw",
		height: "fit-content",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column" as any,
		gap: "10px",
	},
	quickButton: {
		height: "60px",
		width: "75vw",
		paddingLeft: "5vw",
		boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
		fontFamily: '"Montserrat", sans-serif',
		borderRadius: "10px",
		borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
		borderRight: "1px solid rgba(0, 0, 0, 0.1)",
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		position: "relative" as any,
	},
	svgMargin: {
		marginRight: "10px",
	},
	leftArrow: {
		position: "absolute" as any,
		right: "2.5vw",
		top: "50%",
		transform: "translateY(-55%)",
		fontSize: "3rem",
		fontFamily: 'Arial, "Helvetica Neue", sans-serif',
	},
	buttonGroup: {
		display: "flex",
		flexDirection: "column" as any,
		justifyContent: "center",
		alignItems: "center",
		width: "100vw",
		height: "fit-content",
		gap: "20px",
	},
	connexionButton: {
		backgroundColor: "#E7022A",
		padding: "15px",
		borderRadius: "999em",
		width: "75%",
		textAlign: "center" as any,
		color: "#FFFFFF",
		fontWeight: "bold" as any,
	},
	registerButton: {
		backgroundColor: "#FFFFFF",
		padding: "15px",
		borderRadius: "999em",
		border: "1px solid #000000",
		width: "75%",
		textAlign: "center" as any,
		color: "#000000",
	},
	initButton: {
		color: "#000000",
		fontSize: "1rem",
		fontWeight: "bold" as any,
	},
	aide: {
		position: "absolute" as any,
		bottom: "5vh",
		color: "#000000",
		fontSize: "1rem",
		fontWeight: "bold" as any,
		textAlign: "center" as any,
	},
	faceid: {
		position: "absolute" as any,
		top: "0",
		left: "50%",
		transform: "translateX(-50%)",
		height: "20vh",
		zIndex: 1,
		borderRadius: "0 0 20% 20%",
	},
};

interface LoginScreenProps {
	setIsLogged: (isLogged: boolean) => void;
}

export default function LoginScreen(props: LoginScreenProps) {
	const [isFaceId, setIsFaceId] = React.useState(false);
	return (
		<div style={loginStyles.root} id="loginRoot">
			{isFaceId && <img src={faceid} alt="Face ID" style={loginStyles.faceid} />}
			<div style={loginStyles.header}>
				<img
					src="https://banque.meilleurtaux.com/images/actu/logos/societe-generale-logo.png"
					alt="Logo de Société Générale"
					style={loginStyles.icon}
				/>
			</div>
			<div style={loginStyles.quickGlance}>
				<div style={loginStyles.status}></div>
				<div style={loginStyles.statusCutter}>
					<div style={loginStyles.radioButtonFull} />
					<div style={loginStyles.radioButtonEmpty} />
				</div>
			</div>
			<div style={loginStyles.quickActions}>
				<div style={loginStyles.quickButton}>
					<svg fill="#000000" height="60%" viewBox="0 0 611.999 611.999" style={loginStyles.svgMargin}>
						<g>
							<g>
								<g>
									<path
										d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
				C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
				c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
				h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
				c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
				C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
				c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
				c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
				 M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
				c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
				c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"
									/>
									<path
										d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
				c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
				C323.259,126.96,315.532,119.235,306.001,119.235z"
									/>
								</g>
							</g>
						</g>
					</svg>
					Voir mes notifications
					<div style={loginStyles.leftArrow}>›</div>
				</div>
				<div style={loginStyles.quickButton}>
					<svg fill="#000000" height="60%" viewBox="0 0 24 24" style={loginStyles.svgMargin}>
						<path
							fill-rule="evenodd"
							d="M1.75 1A1.75 1.75 0 000 2.75v9.5C0 13.216.784 14 1.75 14H3v1.543a1.457 1.457 0 002.487 1.03L8.061 14h6.189A1.75 1.75 0 0016 12.25v-9.5A1.75 1.75 0 0014.25 1H1.75zM1.5 2.75a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25h-6.5a.75.75 0 00-.53.22L4.5 15.44v-2.19a.75.75 0 00-.75-.75h-2a.25.25 0 01-.25-.25v-9.5z"
						/>
						<path d="M22.5 8.75a.25.25 0 00-.25-.25h-3.5a.75.75 0 010-1.5h3.5c.966 0 1.75.784 1.75 1.75v9.5A1.75 1.75 0 0122.25 20H21v1.543a1.457 1.457 0 01-2.487 1.03L15.939 20H10.75A1.75 1.75 0 019 18.25v-1.465a.75.75 0 011.5 0v1.465c0 .138.112.25.25.25h5.5a.75.75 0 01.53.22l2.72 2.72v-2.19a.75.75 0 01.75-.75h2a.25.25 0 00.25-.25v-9.5z" />
					</svg>
					Posez moi une question
					<div style={loginStyles.leftArrow}>›</div>
				</div>
			</div>
			<div style={loginStyles.buttonGroup}>
				<div
					style={loginStyles.connexionButton}
					onClick={() => {
						setIsFaceId(true);
						setTimeout(() => document.getElementById("loginRoot")?.classList.add("fade-out"), 2000);

						setTimeout(() => props.setIsLogged(true), 2250);
					}}
				>
					Accéder à mes comptes
				</div>
				<div style={loginStyles.registerButton}>Ouvrir un compte</div>
				<div style={loginStyles.initButton}>J'ai déjà initié une demande →</div>
			</div>
			<div style={loginStyles.aide}>
				Aides & Urgences
				<br />△
			</div>
		</div>
	);
}
