import React from "react";
import LoginScreen from "./LoginScreen";
import Account from "./Account";

function App() {
	const [isLogged, setIsLogged] = React.useState(false);
	return isLogged ? <Account setIsLogged={setIsLogged} /> : <LoginScreen setIsLogged={setIsLogged} />;
}

export default App;
