import React from "react";
import { type AccountType, type Card, accountMock } from "./database";

const accountStyles = {
	root: {
		display: "flex",
		flexDirection: "column" as any,
		justifyContent: "start",
		alignItems: "center",
		height: "100vh",
		width: "100vw",
		backgroundColor: "#FFFFFF",
		transition: "all 0.25s",
		animation: "fade-in 0.25s",
	},
	header: {
		height: "120px",
		width: "100vw",
		backgroundColor: "#ED3A44",
		position: "relative" as any,
		flexShrink: 0,
	},
	buttonRow: {
		position: "absolute" as any,
		top: "50%",
		right: "10px",
		transform: "translate(0, -50%)",
		zIndex: 1,
		display: "flex",
		gap: "10px",
	},
	menuBar: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: "fit-content",
		paddingBottom: "10px",
		width: "95vw",
		backgroundColor: "#ED3A44",
		position: "absolute" as any,
		bottom: "0",
		left: "50%",
		transform: "translate(-50%, 0)",
	},
	menuButton: {
		color: "#FFFFFF",
		fontFamily: '"Montserrat", sans-serif',
		fontSize: "14px",
		padding: "10px",
		borderRadius: "5px",
	},
	menuButtonSelected: {
		color: "#FFFFFF",
		fontFamily: '"Montserrat", sans-serif',
		fontSize: "14px",
		backgroundColor: "#E7022A",
		padding: "10px",
		borderRadius: "5px",
		fontWeight: "bold",
	},
};

interface AccountProps {
	setIsLogged: (isLoggedIn: boolean) => void;
}

export default function Account(props: AccountProps) {
	const [selectedMenu, setSelectedMenu] = React.useState("Comptes");
	return (
		<div style={accountStyles.root}>
			<div style={accountStyles.header}>
				<div style={accountStyles.buttonRow}>
					<svg fill="#FFFFFF" height="7vw" viewBox="0 0 611.999 611.999">
						<g>
							<g>
								<g>
									<path
										d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
				C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
				c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
				h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
				c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
				C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
				c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
				c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
				 M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
				c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
				c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"
									/>
									<path
										d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
				c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
				C323.259,126.96,315.532,119.235,306.001,119.235z"
									/>
								</g>
							</g>
						</g>
					</svg>
					<svg
						height="7vw"
						viewBox="0 0 1200 1200"
						fill="#FFFFFF"
						onClick={() => {
							props.setIsLogged(false);
						}}
					>
						<path
							d="M513.94,0v693.97H686.06V0H513.94z M175.708,175.708
	C67.129,284.287,0,434.314,0,600c0,331.371,268.629,600,600,600s600-268.629,600-600c0-165.686-67.13-315.713-175.708-424.292
	l-120.85,120.85C981.102,374.216,1029.126,481.51,1029.126,600c0,236.981-192.146,429.126-429.126,429.126
	c-236.981,0-429.126-192.145-429.126-429.126c0-118.49,48.025-225.784,125.684-303.442L175.708,175.708z"
						/>
					</svg>
				</div>
				<div style={accountStyles.menuBar}>
					<div
						style={selectedMenu === "Comptes" ? accountStyles.menuButtonSelected : accountStyles.menuButton}
						onClick={() => setSelectedMenu("Comptes")}
					>
						Comptes
					</div>
					<div
						style={
							selectedMenu === "Emprunter" ? accountStyles.menuButtonSelected : accountStyles.menuButton
						}
						onClick={() => setSelectedMenu("Emprunter")}
					>
						Emprunter
					</div>
					<div
						style={
							selectedMenu === "Épargner" ? accountStyles.menuButtonSelected : accountStyles.menuButton
						}
						onClick={() => setSelectedMenu("Épargner")}
					>
						Épargner
					</div>
					<div
						style={selectedMenu === "Assurer" ? accountStyles.menuButtonSelected : accountStyles.menuButton}
						onClick={() => setSelectedMenu("Assurer")}
					>
						Assurer
					</div>
				</div>
			</div>
			<Section sectionName={selectedMenu} />
		</div>
	);
}

interface SectionProps {
	sectionName: string;
}

const sectionStyles = {
	comptes: {
		root: {
			display: "flex",
			flexDirection: "column" as any,
			justifyContent: "start",
			alignItems: "center",
			width: "100vw",
			height: "100%",
			backgroundColor: "#EEEEEE",
		},
		suggestionsContainer: {
			display: "flex",
			flexDirection: "row" as any,
			justifyContent: "start",
			alignItems: "center",
			width: "100%",
			height: "100px",
			paddingBottom: "10px",
			overflowX: "scroll" as any,
			overflowY: "hidden" as any,
			marginTop: "10px",
		},
		suggestion: {
			display: "flex",
			flexDirection: "row" as any,
			justifyContent: "start",
			alignItems: "center",
			width: "60vw",
			height: "100%",
			backgroundColor: "#FFFFFF",
			borderRadius: "10px",
			marginLeft: "10px",
			paddingLeft: "10px",
			gap: "10px",
			flexShrink: 0,
			boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
		},
		subTitle: {
			width: "90%",
			fontFamily: '"Montserrat", sans-serif',
			marginTop: "25px",
			marginBottom: "0px",
		},
		weirdUnderline: {
			width: "20vw",
			height: "5px",
			backgroundColor: "#ED3A44",
			borderRadius: "5px",
			translate: "20vw",
			marginBottom: "10px",
		},
		accountContainer: {
			display: "flex",
			flexDirection: "column" as any,
			justifyContent: "start",
			alignItems: "center",
			width: "90vw",
			height: "fit-content",
			backgroundColor: "#FFFFFF",
			borderRadius: "10px",
			boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
			marginBottom: "10px",
		},
		accountSection: {
			width: "100%",
			position: "relative" as any,
			height: "80px",
		},
		accountSectionElem: {
			title: {
				fontSize: "16px",
				margin: "0",
				position: "absolute" as any,
				top: "50%",
				left: "20px",
				transform: "translate(0, -100%)",
			},
			id: {
				fontSize: "14px",
				margin: "0",
				position: "absolute" as any,
				bottom: "10px",
				left: "20px",
			},
			value: {
				fontSize: "16px",
				margin: "0",
				position: "absolute" as any,
				top: "50%",
				right: "20px",
				transform: "translate(0, -100%)",
				color: "#3C9844",
				fontWeight: "bold",
			},
		},
		accountCard: {
			borderTop: "1px solid #888888833",
			width: "calc(100% - 40px)",
			display: "flex",
			flexDirection: "row" as any,
			justifyContent: "start",
			alignItems: "center",
			paddingTop: "5px",
			paddingBottom: "5px",
			paddingLeft: "40px",
		},
		buttonComptes: {
			width: "70vw",
			height: "60px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "2px solid #222222",
			borderRadius: "999em",
			fontFamily: '"Montserrat", sans-serif',
			fontWeight: "bold",
			fontSize: "16px",
			marginTop: "20px",
		},
	},
	emprunter: {
		root: {
			display: "flex",
			flexDirection: "column" as any,
			justifyContent: "start",
			alignItems: "center",
			width: "100vw",
			height: "100%",
		},
	},
	epargner: {
		root: {
			display: "flex",
			flexDirection: "column" as any,
			justifyContent: "start",
			alignItems: "center",
			width: "100vw",
			height: "100%",
		},
	},
	assurer: {
		root: {
			display: "flex",
			flexDirection: "column" as any,
			justifyContent: "start",
			alignItems: "center",
			width: "100vw",
			height: "100%",
		},
	},
};

function Section(props: SectionProps) {
	switch (props.sectionName) {
		case "Comptes":
			return (
				<div style={sectionStyles.comptes.root}>
					<div style={sectionStyles.comptes.suggestionsContainer}>
						<div style={sectionStyles.comptes.suggestion}>
							<img
								src="https://yt3.googleusercontent.com/ytc/AIdro_mNyuLInMXrOLPTCNV_3oywLY2dgbUSEmyf8IbIO_xB2g=s900-c-k-c0x00ffffff-no-rj"
								alt="pl"
								height="80px"
								width="80px"
								style={{ borderRadius: "10px" }}
							/>
							<div>
								<h3>Paylib</h3>
								<p>Paiements entre amis</p>
							</div>
						</div>
						<div style={sectionStyles.comptes.suggestion}>
							<img
								src="https://fiches-pratiques.chefdentreprise.com/Assets/Img/FICHEPRATIQUE/2020/6/351203/Tout-savoir-cashback-F.jpg"
								alt="pl"
								height="80px"
								width="80px"
								style={{ borderRadius: "10px" }}
							/>
							<div>
								<h3>Cashback</h3>
								<p>Remboursement de vos achats</p>
							</div>
						</div>
						<div style={sectionStyles.comptes.suggestion}>
							<img
								src="https://yt3.googleusercontent.com/ytc/AIdro_mNyuLInMXrOLPTCNV_3oywLY2dgbUSEmyf8IbIO_xB2g=s900-c-k-c0x00ffffff-no-rj"
								alt="pl"
								height="80px"
								width="80px"
								style={{ borderRadius: "10px" }}
							/>
							<div>
								<h3>Paylib</h3>
								<p>Paiements entre amis</p>
							</div>
						</div>
					</div>
					<h2 style={sectionStyles.comptes.subTitle}>Mes comptes courants</h2>
					<div style={sectionStyles.comptes.weirdUnderline} />
					{accountMock.map((account: AccountType) => (
						<div style={sectionStyles.comptes.accountContainer}>
							<div style={sectionStyles.comptes.accountSection}>
								<h3 style={sectionStyles.comptes.accountSectionElem.title}>{account.name}</h3>
								<p style={sectionStyles.comptes.accountSectionElem.id}>
									N° ****{account.id.toString().substring(7)}
								</p>
								<p style={sectionStyles.comptes.accountSectionElem.value}>
									{account.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
									{account.currency}
								</p>
							</div>
							{account.availableCards.map((card: Card) => (
								<div style={sectionStyles.comptes.accountCard}>
									<img
										src={
											card.type === "visa"
												? "https://pngimg.com/d/credit_card_PNG37.png"
												: "https://e7.pngegg.com/pngimages/836/850/png-clipart-credit-card-debit-card-mastercard-payment-card-credit-card-payment-internet.png"
										}
										alt={card.type}
										width="50px"
									/>
									<div>
										<h4 style={{ fontSize: "16px", margin: "0" }}>Carte {card.type}</h4>
										<p style={{ fontSize: "12px", margin: "0" }}>
											N°**** ****{card.number.substring(9)}
										</p>
									</div>
								</div>
							))}
						</div>
					))}
					<div style={sectionStyles.comptes.buttonComptes}>Voir tous mes comptes</div>
				</div>
			);
		case "Emprunter":
			return <div style={sectionStyles.emprunter.root}>Emprunter</div>;
		case "Épargner":
			return <div style={sectionStyles.epargner.root}>Épargner</div>;
		case "Assurer":
			return <div style={sectionStyles.assurer.root}>Assurer</div>;
	}
	return <></>;
}
