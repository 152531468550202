export interface Card {
	id: number;
	name: string;
	number: string;
	expirationDate: string;
	cvv: string;
	type: string;
}

export interface AccountType {
	id: number;
	name: string;
	balance: number;
	currency: string;
	availableCards: Card[];
}

export const accountMock: AccountType[] = [
	{
		id: 34567654319,
		name: "Compte courant",
		balance: 4263291.54,
		currency: "€",
		availableCards: [
			{
				id: 1,
				name: "Visa",
				number: "4293 2893 1029 7528",
				expirationDate: "01/23",
				cvv: "123",
				type: "visa",
			},
			{
				id: 2,
				name: "MasterCard",
				number: "7293 0293 8219 3378",
				expirationDate: "02/24",
				cvv: "234",
				type: "mastercard",
			},
		],
	},
	{
		id: 64267454120,
		name: "Livret A",
		balance: 5768.56,
		currency: "€",
		availableCards: [],
	},
];
